.TaggingModal
  --width: 100%
  border: none
  .modal-shadow
    display: none
  .modal-wrapper
    position: fixed
    left: 0
    bottom: 0
    height: 60%
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    transition: height .4s .3s ease
    z-index: 30000 !important
  &-top-controls
    position: fixed
    top: 0
    left: 0
    width: 100%
    background-color: var(--ion-color-primary-contrast)
    z-index: 99999999999
    &__dash
      display: block
      margin: 0 auto
      margin-top: .5rem
      width: 3rem
      height: 5px
      background-color: var(--ion-color-medium)
      border-radius: 50px
    .BackButton
      padding-left: 1rem
      margin-bottom: .2rem
      cursor: pointer
      z-index: 99999999999
      &::after
        content: ''
        position: absolute
        left: 0
        bottom: -.5rem
        width: calc(100% + .5rem)
        height: .3rem
        box-shadow: inset 0px 10px 8px -10px rgba(#000, 0.2) 
  &-content
    width: 100%
    height: 100%
    --padding-bottom: 1rem
    text-align: center
    --keyboard-offset: 0 !important
    &-item
      --padding-bottom: 1rem
      &__title-block 
        font-size: 14px
    &-upload-btn
      position: relative
      div
        margin-bottom: 12px
        width: 6.4rem
        height: 6.4rem
        img
          width: 100%
          height: 100%
          border-radius: 8px
          object-fit: cover
    &-btn
      margin: 0
      width: 100%

  &--small-size
    @extend .TaggingModal
    .modal-wrapper
      height: 11.5rem

body.dark
  .TaggingModal
    &-top-controls,
    &-content,
    &-content-item,
    .TaggingModal-content-item__title-block 
      background: var(--ion-background-color)
      --background: var(--ion-background-color)