.Welcome
  .swiper-pagination
    transform: translateY(-2.5rem)
  ion-slides
    height: 100%
  &-slide
    display: flex
    flex-flow: column
    align-items: center
    &__icon
      font-size: 6em
    &__title
      margin-top: 5rem
      margin-bottom: 1rem
      font-size: 28px
      color: var(--ion-color-primary)
    &__description
      margin-top: 0
      width: 80%
      color: var(--ion-color-medium)