.FollowersCard
  margin-left: 0
  margin-right: 0
  &-item
    --inner-border-width: 0
    opacity: 1
    transition: opacity .3s ease-out
    &:not(:last-child)
      --padding-bottom: 1rem
    &-wrapper
      display: flex
      align-items: center
      justify-content: center
    &__image
      margin-right: 1.2rem
      width: 3rem !important
      height: 3rem !important
      min-width: 3rem !important
      object-fit: cover
      border-radius: 50%
    &__info
      h3
        font-size: 1em
        color: var(--ion-color-dark)
    &__btn
      margin-right: 0
      transform: translateX(.85rem)

body.dark
  .FollowersCard, .FollowersCard-item
    --background: var(--ion-color-light-shade)