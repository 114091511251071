.Comment
  &__avatar
    a img
      width: 100%
      height: 100%
      border-radius: 50%
      object-fit: cover
  &__title
    display: flex
    align-items: center
    h2
      display: flex
      align-items: center
      color: var(--ion-color-dark)
      font-size: .8rem
      ion-icon
        margin-left: 0.1rem
    &__time
      color: var(--ion-color-medium)
      font-size: .8rem
      &::before
        content: '•'
        margin: 0 .3rem
  p
    margin-bottom: 0
    font-size: 1rem
    color: var(--ion-color-dark)
    transform: translateY(2px)
    white-space: pre-wrap
  &--reply
    @extend .Comment
    --padding-start: 3.5rem
  &-button-wrapper
    text-align: right
    ion-button
      margin: 0
