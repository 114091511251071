.Tag
  position: absolute
  top: 98px
  left: 179px
  width: 1rem
  height: 1rem 
  max-width: 2rem
  max-height: 2rem
  border-radius: 50%
  transition: background-color .15s ease-in
  -webkit-box-shadow: 0px 0px 20px 2px #9D9D9D
  box-shadow: 0px 0px 20px 2px #9D9D9D
  z-index: 100