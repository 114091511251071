.IOSStaticHeader
  display: block
  margin-left: 70px
  width: calc(100% - 70px)
  height: 44px
  z-index: 101
  ion-title
    transform: translateX(-35px)

body.dark
  .IOSStaticHeader
    ion-toolbar
      --background: var(--ion-color-light-shade)