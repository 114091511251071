.ProfileSettings
  &__avatar
    position: relative
    margin: 0 auto
    width: 6em
    height: 6em
    object-fit: fill
    &-overlay
      position: absolute
      display: flex
      align-items: center
      justify-content: center
      width: 100%
      height: 100%
      color: var(--ion-color-light)
      background-color: rgba(#000, 0.3)
      font-size: 3.2rem
      border-radius: 50%

body.dark
  .ProfileSettings
    .ProfileSettings__avatar-overlay
      color: var(--ion-text-color)