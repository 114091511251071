.TagPopover
  position: absolute
  display: flex
  padding: .3rem
  align-items: center
  justify-content: center
  min-width: 3rem
  min-height: 1.5rem
  width: auto
  height: auto
  color: var(--ion-color-dark-contrast)
  font-size: 1rem
  background-color: rgba(var(--ion-color-dark-rgb), 0.8)
  border-radius: 4px
  transform: translate(calc(-50% + .5rem), 1.25rem)
  animation: fadeIn 0.25s ease-in
  z-index: 9999999
  &__polygon
    position: absolute
    top: -.25rem
    left: 50%
    width: 0
    height: 0
    border-left: 0.3rem solid transparent
    border-right: 0.3rem solid transparent
    border-bottom: .25rem solid rgba(var(--ion-color-dark-rgb), 0.8)
    transform: translateX(-50%)
  &--inverted
    @extend .TagPopover
    transform: translate(calc(-50% + .5rem), calc(-100% - 0.25rem))
    .TagPopover__polygon
      top: 100%
      border-top: .25rem solid rgba(var(--ion-color-dark-rgb), 0.8)
      border-bottom: none
