.MasonryGrid
  padding: 5px
  transition: all .2s ease-out
  react-columned-column
    transition: all .2s ease-out
    react-columned-item
      margin: 0.5rem 0
      border-radius: 8px
      box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px
  &--two-columns
    transition: all .2s ease-out
    react-columned-column
      react-columned-item
        margin-bottom: 10px
        border-radius: 8px
        box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px
      width: calc(50% - 5px) !important
      transition: all .2s ease-out
      &:nth-of-type(1)
        margin-right: 5px
      &:nth-of-type(2)
        margin-left: 5px