.MyCarForm
  &__select,
  &__date-picker
    --padding-top: 24px
  &__checkbox
    --inner-border-width: 0
  &__button
    margin: 1.5rem 0
    width: 100%

body.dark
  .MyCarForm
    .item-interactive-disabled
      ion-label, ion-checkbox
        opacity: 0.7 !important
      