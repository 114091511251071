.Gallery
  display: grid
  grid-template-columns: repeat(3, 1fr)
  justify-content: start
  grid-row-gap: 3vw
  grid-column-gap: 3vw
  div:nth-child(3n)
    text-align: right
  div:nth-child(3n + 2)
    text-align: center
  &__item
    position: relative
    width: 100%
    border-radius: 8px
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    &:after
      content: ''
      display: block
      padding-top: 100%
  &-overlay
    position: absolute
    top: 0
    right: 0
    display: flex
    width: 100%
    height: 100%
    align-items: center
    justify-content: center
    color: var(--ion-color-primary-contrast)
    font-weight: bold
    font-size: 1.5rem
    border-radius: 8px
    background-color: rgba(0, 0, 0, 0.3)
