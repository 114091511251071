.CollapsibleTab
  position: relative
  margin-bottom: 1.5rem
  padding: 20px
  border-radius: 8px
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px
  background-color: var(--ion-color-primary-contrast)
  &__trigger
    display: inline-block
    width: 90%
    color: var(--ion-color-primary)
    font-weight: 600
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    &::after
      content: ''
      position: absolute
      top: 26px
      right: 22px
      width: 10px
      height: 10px
      border-bottom: 2px solid var(--ion-color-primary)
      border-right: 2px solid var(--ion-color-primary)
      transform: translateY(-50%) rotate(45deg)
      transition: all .15s ease
  .is-open
    &::after
      transform: translateY(-50%) rotate(-135deg)
  &__contentInner
    p:last-child
      margin-bottom: 0

body.dark
  .CollapsibleTab
    background: var(--ion-color-light-shade)
