.MyLikesCard
  margin-left: 0
  margin-right: 0
  &-item
    --inner-border-width: 0
    opacity: 1
    transition: opacity .3s ease-out
    --padding-top: .5rem
    --padding-bottom: .5rem
    &:not(:last-child)
      border-bottom: 1px solid var(--ion-color-light)
    &-wrapper
      display: flex
      align-items: center
      justify-content: center
    &__image
      margin-right: 1.2rem
      width: 3rem !important
      height: 3rem !important
      min-width: 3rem !important
      object-fit: cover
      border-radius: 4px
    &__info
      h3
        font-size: 1em
        color: var(--ion-color-dark)
    &__icon
      transform: translateX(2rem)

body.dark
  .MyLikesCard, .MyLikesCard-item
    --background: var(--ion-color-light-shade)