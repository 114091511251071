.BackButton
  display: flex
  margin-bottom: 1rem
  align-items: center
  text-align: left
  font-size: 17px
  transform: translateX(-.5rem)
  &__icon
    font-size: 1.85em
  &__text
    transform: translateX(-.3rem)