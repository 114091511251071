.ComponentInputs
  &-grouped-input
    padding: 0
    width: 1.3rem !important
    border: none
    outline: none
    text-transform: uppercase
    &:not(:first-child)
      margin: 0 .2rem
    &--4-symbols
      @extend .ComponentInputs-grouped-input
      width: 3rem !important
  &-separator
    margin: 0 .5rem
    text-align: center
