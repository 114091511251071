.DetailsCard
  margin: 0
  margin-top: 1rem
  &-item
    &-value
      margin: 0
      transform: translateX(0.5rem)
  &-footer
    display: flex
    margin-top: 20px
    justify-content: space-between
    &__button
      display: flex
      width: 50%
      align-items: center
      justify-content: center
      font-size: 1.2rem
      ion-icon
        font-size: 1.3rem
        margin-right: .3rem

body.dark
  .DetailsCard,
  .DetailsCard-item,
  .DetailsCard-content
    background-color: var(--ion-color-light-shade)
    --background: var(--ion-color-light-shade)