.Menu
  a
    color: inherit
    text-decoration: none
    outline: none
  &-footer
    margin-bottom: 56px

body.dark
  .Menu
    ion-toolbar, ion-content, .Menu__item
      --background: var(--ion-color-light-shade)
    