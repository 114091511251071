.Component
  &-image
    margin-top: 1rem
    width: 100%
    border-radius: 8px
    object-fit: cover
  &-get-btn
    position: relative
    width: 100%
    text-transform: uppercase
    ion-icon
      position: absolute
      right: 0