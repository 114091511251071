.IconWithBadge
  position: relative
  &::before
    content: ''
    position: absolute
    top: 0
    right: 0
    width: .5rem
    height: .5rem
    background-color: var(--ion-color-danger)
    border-radius: 50%