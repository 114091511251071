.TagNewComponent
  &-components-list
    &__title
      display: flex
      margin-top: 1rem
      margin-bottom: 1rem
      align-items: center
      justify-content: center
      span:before
        content: ''
        display: flex
        margin: 0 .5rem
        align-items: center
        width: 2rem
        height: 1px
        background-color: var(--ion-color-dark)
        transform: translateY(2px)