.ShowroomCarCard
  margin: 0
  box-shadow: none
  transition: all .2s ease-out
  &-content
    display: flex
    justify-content: space-between
    align-items: center
  &-card-info
    width: 78%
    div:first-child
      display: flex
      justify-content: space-between
    &__title,
    &__owner
      margin-top: 0
      font-size: 1.1rem
      transition: all .2s ease-out
    &__title
      max-width: 60%
      margin-top: -10px
      --inner-border-width: 0
      color: var(--ion-color-dark)
      font-weight: bold
    &__owner
      color: var(--ion-color-dark-tint)
    &__chip
      margin: 0
      border-color: var(--ion-color-primary)
      transform: translateY(-.2rem)
      &:active,
      &:focus
        background-color: transparent
  &-like-zone
    transform: translateY(-3px)
  &--shrinked
    display: inline-block
    margin: 0
    width: 100%
    .ShowroomCarCard
      &-card-info
        &__title
          max-width: 100%
          font-size: 0.8rem
          --min-height: 0
        &__owner
          font-size: 0.9rem
          margin-top: -.2rem
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
        &__chip
          display: none
      &-like-zone
        transform: translateX(-15%)
        .LikeButton--big-icon
          height: 25px

body.dark
  .ShowroomCarCard,
  .ShowroomCarCard-card-info__title
    --background: var(--ion-color-light-shade)