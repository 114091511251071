.CommentInput
  margin: 1rem 0 0.5rem
  &__input
    margin-bottom: .5rem
  &__chip
    --background: rgba(var(--ion-color-light-rgb), 0.6)
    ion-icon
      transform: translateY(-.1rem)
    ion-label
      margin-left: -.5rem
  &__button
    margin: 0
    width: 100%
  &__link
    display: block
    margin: 0 auto
    text-align: center

body.dark
  .CommentInput__chip ion-icon
    color: var(--ion-text-color)
