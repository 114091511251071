.UserInfoCard
  margin-left: 0
  margin-right: 0
  &-content
    display: flex
    align-items: center
    font-size: 16px
  &-avatar
    margin-right: 1rem
    width: 6em
    height: 6em
  &-info-wrapper
    display: flex
    align-items: center
    justify-content: space-between
    min-width: calc(100% - 112px)
    &--small
      @extend .UserInfoCard-info-wrapper
      min-width: calc(100% - 90px)
    ion-chip
      margin-right: 0
  &-info
    display: flex
    flex-flow: column
    font-size: 1em
    color: var(--ion-color-dark)
    &__name
      display: flex
      align-items: center
      margin: 0
      margin-bottom: 0.3em !important
      font-size: 1.2em !important
      font-weight: bold !important
      ion-icon 
        margin-left: .15rem
        transform: translateY(.5px)
    &__location
      margin-bottom: 0.3em
      text-transform: capitalize
  &-stats
    display: flex
    margin-top: 1rem
    align-items: center
    justify-content: space-between
    &__stat
      display: flex
      align-items: center
      flex-flow: column
      color: var(--ion-color-dark)
      span
        display: block
        margin-top: 0.15rem
        color: var(--ion-color-medium)

@media screen and (max-width: 360px)
  .UserInfoCard
    &-avatar
      width: 5em
      height: 5em
    &-info-wrapper
      min-width: calc(100% - 96px)
      &--small
        min-width: calc(100% - 75px)
    &-info
      font-size: .8em

body.dark
  .UserInfoCard
    --background: var(--ion-color-light-shade)