.AuthForms
  &__checkbox
    margin-top: 2rem
    --inner-border-width: 0
  &__button
    margin: 0
    margin-top: 2rem
    width: 100%
  &__link
    display: block
    margin-top: 1.5rem