.TaggingContainer
  position: relative
  width: 100%
  height: auto
  font-size: 0
  &--landscape
    position: relative
    width: auto
    height: 100%
  &-image
    width: 100% !important
    height: auto !important
    object-fit: contain
    &--landscape
      width: auto !important
      height: 100% !important
      object-fit: contain