.NarrowCardList
  margin: 0
  padding-top: 1rem
  padding-bottom: 1rem
  &-content
    padding: 0 16px
  .shadow
    position: absolute
    top: 1rem
    left: 16px
    z-index: 999
    width: calc(100% - 32px)
    height: 1rem
    box-shadow: inset 1px 10px 22px -10px rgba(#000, 0.1) 
    
  &-item
    --inner-border-width: 0
    border-bottom: 0px solid var(--ion-color-light)
    &__image
      width: 3rem !important
      height: 3rem !important
      min-width: 3rem !important
      object-fit: cover
      border-radius: 4px
    &__info
      h3
        font-size: 1em
        color: var(--ion-color-dark)
    &__icon
      margin: 0
      opacity: 0.3
      transform: translateX(1rem)

body.dark
  .NarrowCardList, .NarrowCardList-item
    --background: var(--ion-color-light-shade)