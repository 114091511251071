.LevelUpModal
  display: flex
  height: 100%
  align-items: center
  justify-content: center
  flex-flow: column
  text-align: center
  &__trophy
    font-size: 6rem
    animation: tada 1s 0.5s ease
  &__title
    margin-top: 5rem
    margin-bottom: 1rem
    font-size: 1.4rem
  &__description
    margin-top: 0
    width: 80%
    color: var(--ion-color-medium)

@keyframes tada
  from
    transform: scale3d(1, 1, 1)
  10%,
  20%
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg)
  30%,
  50%,
  70%,
  90%
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg)
  40%,
  60%,
  80%
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg)
  to
    transform: scale3d(1, 1, 1)
