body
  color: var(--ion-color-primary-contrast)
  background-color: var(--ion-color-primary-contrast)

ion-item
  --padding-start: 0

.ion-item-padding-start
  --padding-start: 20px

.Link
  text-decoration: none

.infinite-spinner
  ion-spinner
    color: var(--ion-color-primary)

ion-picker
  touch-action: none

.liked
  animation: pulse .2s ease-in-out
.disabled
  animation: wobble .5s

.forward-icon
  opacity: 0.3
  transform: translateX(1.2rem)

body.dark
  ion-tab-bar
    --background: var(--ion-color-light-shade)

@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1

@keyframes fadeOut
  from
    opacity: 1
  to
    opacity: 0

@keyframes pulse
  from
    transform: scale3d(1, 1, 1)
  50%
    transform: scale3d(1.15, 1.15, 1.15)
  to
    transform: scale3d(1, 1, 1)

@keyframes wobble
  from
    transform: translate3d(0, 0, 0)
  15%
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
  30%
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
  45%
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
  60%
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
  75%
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
  to
    transform: translate3d(0, 0, 0)
