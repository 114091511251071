.FullscreenGallerySlider
  --width: 100%
  --height: 100%
  --border-radius: 0
  &-toolbar
    position: absolute
    top: 0
    top: constant(safe-area-inset-top) // Status bar height on iOS 11.0
    top: env(safe-area-inset-top) // Status bar height on iOS 11+
    left: 0
    color: var(--ion-color-light)
    --background: rgba(var(--ion-color-dark-rgb), 0.8)
    z-index: 99999
    &__close-btn
      --color: var(--ion-color-light)
    &__edit-btn
      margin-right: .8rem
      --padding-start: .85rem !important
      --padding-end: .8rem !important
      height: 2rem
      --background-hover-opacity: 0
      --background-focused-opacity: 0
      --background-activated-opacity: 0
      --color: var(--ion-color-primary)
      ion-icon
        height: 1.2rem
    &__popover-btn
      @extend .FullscreenGallerySlider-toolbar__edit-btn      
    &__counter
      margin-left: -.3rem
      margin-right: 12px
      width: 3.3rem
  &-slider
    width: 100vw
    height: 100vh
    color: var(--ion-color-light)
    background: var(--ion-color-dark)

body.dark
  .FullscreenGallerySlider
    &-toolbar
      color: var(--ion-color-text)
      --background: rgba(var(--ion-background-color), 0.8)
      &__close-btn
        --color: var(--ion-color-text)
    &-slider
      background: var(--ion-background-color)