.ImageUploader
  margin-top: 2rem
  div
    padding-bottom: calc(3vw + 1.5vw)
  & > div:nth-child(3n + 1)
    display: flex
    padding-right: 3vw
    padding-left: 0
  & > div:nth-child(3n + 2)
    display: flex
    padding-left: 1.5vw
    padding-right: 1.5vw
    justify-content: center
  & > div:nth-child(3n)
    display: flex
    padding-left: 3vw
    padding-right: 0
    justify-content: flex-end
  &-btn
    display: flex
    align-items: center
    justify-content: center
    font-size: 4em
    border: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)))
    ion-icon
      transform: translateY(.45rem)
  &__item
    display: flex
    width: 100%
    justify-content: center
    align-items: center
    border-radius: 8px
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    background-color: var(--ion-color-primary-contrast)
    &::after
      content: ''
      display: block
      padding-top: 100%
  &__hint
    color: rgba(var(--ion-color-primary-rgb), 0.3)

body.dark
  .ImageUploader__hint
    color: rgba(var(--ion-color-primary-rgb), 0.7)
  .ImageUploader-btn
    background: var(--ion-color-light-shade)